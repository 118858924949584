import React from "react"
import styled from "styled-components"
import Image from "../components/Image"

const BackgroundSection = ({ children, image, link, fb }) => {
  const confirmText =
    "Dieser Link leitet dich auf die Rockit.myRide Facebook-Seite weiter"
  const consent = e => {
    window.confirm(confirmText)
      ? (window.location.href = link)
      : e.preventDefault()
  }
  const content = (
    <>
      <Image imgsrc={image} />
      <div className="service-tile-content">
        <span>{children}</span>
      </div>
    </>
  )

  return (
    <a href={link} onClick={fb && consent} className="service-tile">
      {content}
    </a>
  )
}

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
