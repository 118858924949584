import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

export default class Image extends React.Component {
  render(){
    return (
      <StaticQuery
        query={graphql`
          query {
            allImageSharp {
              edges {
                node {
                  fluid(maxWidth: 500, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <Img fluid={data.allImageSharp.edges.find((element) => {
              // Match string after final slash
              return (element.node.fluid.src.split('/').pop() === this.props.imgsrc);
            }).node.fluid} />
          )
        }}
      />
    )
  }
}
