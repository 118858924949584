import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/Hero'
import ServiceTile from '../components/ServiceTile'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Hero style={{height: 'calc(100vh - 7rem)'}} className="hero is-fullheight">
      <div className="hero-body">
        <div style={{paddingBottom: '0'}} className="container text-auf-bild-magic">
          <h1 className="title"><span className="shadow-magic"><span>wir stemmen fast alles.</span></span></h1>
          <div className='service-tile-container'>
            <ServiceTile image="Rockit_EXPERT.jpg" link="https://rockit.expert/">
              Die Kooperationsplattform - <br />
              Deutschlands Expertenfinder <br />
              <b>für Finanzdienstleister</b>
            </ServiceTile>
            <ServiceTile image="Rockit_IRON.jpg" link="https://www.rockit-iron.de/">
              Die Maklerschmiede - <br />
              Wir liefern den Amboss, <br />
              halten die Esse auf Temperatur - <br />
              zuschlagen musst Du selber!
            </ServiceTile>
            <ServiceTile fb={true} image="Rockit_myRide.jpg" link="https://www.facebook.com/Rockit-My-Ride-111514350194734">
              Die Leasingplattform - <br />
              Regelmäßig aktuelle Leasingaktionen <br />
              <b>für Gewerbetreibende.</b>
            </ServiceTile>
          </div>
        </div>
      </div>
      <div className="has-text-white has-text-centered hero-foot">
        <div className='footer-container container'>
          <span>
            <b>Rockit.</b> wächst und wächst, die Ideen sind zahlreich und so fassen wir auf dieser kleinen Seite einmal unsere Marken zusammen. Wenn ihr auf das jeweilige Logo klickt, werdet ihr zur jeweiligen Seite weiter geleitet und seht dort, was genau dahintersteckt.
          </span>
        </div>
      </div>
    </Hero>
  </Layout>
)

export default IndexPage
